import * as Type from "./types";

const marketingSettingReducer = (
  state = { marketingSettingActiveTab: "contacts" },
  action
) => {
  switch (action.type) {
    case Type.SET_MARKETING_ACTIVE_TAB:
      state = { marketingSettingActiveTab: action.payload };
      break;
    default:
      break;
  }
  return state;
};
export default marketingSettingReducer;
