// import * as Types from "./types";

// export const SetUserWorkspaceSettings = (data) => ({
//   type: Types.SET_USER_WORKSPACE_SETTINGS,
//   payload: data,
// });

import * as Types from "./types";

// Existing action
export const SetUserWorkspaceSettings = (data) => ({
  type: Types.SET_USER_WORKSPACE_SETTINGS,
  payload: data,
});

// Action to add a new description template
export const AddUserWorkspaceSettings = (key, data) => ({
  type: Types.ADD_USER_WORKSPACE_SETTINGS,
  payload: {
    key,
    data,
  },
});

// Action to update a description template
export const UpdateUserWorkspaceSettings = (key, data) => ({
  type: Types.UPDATE_USER_WORKSPACE_SETTINGS,
  payload: {
    key,
    data,
  },
});

// Action to remove a description template
export const RemoveFromUserWorkspaceSettings = (key, id) => ({
  type: Types.REMOVE_FROM_USER_WORKSPACE_SETTINGS,
  payload: {
    key,
    id,
  },
});
