import * as Type from "./types";
import { uniqBy } from "lodash";

const ConversationListReducer = (
  state = {
    loading: true,
    data: [],
    conversations: [],
    page: 2,
    closedConversations: [],
  },
  action
) => {
  switch (action.type) {
    case Type.SET_CONVERSATION_ARR:
      return {
        ...state,
        data: action.payload,
      };

    case Type.SET_CONVERSATIONS:
      return {
        ...state,
        conversations: action.payload,
      };

    case Type.SET_CLOSED_CONVERSATION_ARR:
      let mergedConversations = [
        ...(action.payload || []),
        ...(state.closedConversations || []),
      ];
      if (mergedConversations.length > 0) {
        mergedConversations = uniqBy(mergedConversations, "_id");
      }
      return {
        ...state,
        closedConversations: mergedConversations,
      };

    case Type.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case Type.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default ConversationListReducer;
