// import * as Type from "./types";

// const initialState = {
//   workspace: null,
//   userPermissions: [],
//   integrations: [],
//   members: [],
//   descriptionTemplates: [],
// };

// const UserWorkspaceSettingsReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case Type.SET_USER_WORKSPACE_SETTINGS:
//       return {
//         ...state,
//         workspace: action.payload?.workspace || null,
//         userPermissions: action.payload?.userPermissions || [],
//         integrations: action.payload?.integrations || [],
//         members: action.payload?.members || [],
//         descriptionTemplates: action.payload?.descriptionTemplates || [],
//       };
//     default:
//       return state;
//   }
// };

// export default UserWorkspaceSettingsReducer;
import * as Type from "./types";

const initialState = {
  workspace: null,
  userPermissions: [],
  integrations: [],
  members: [],
  descriptionTemplates: [],
};

const UserWorkspaceSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.SET_USER_WORKSPACE_SETTINGS:
      return {
        ...state,
        workspace: action.payload?.workspace || null,
        userPermissions: action.payload?.userPermissions || [],
        integrations: action.payload?.integrations || [],
        members: action.payload?.members || [],
        descriptionTemplates: action.payload?.descriptionTemplates || [],
      };
    // case Type.ADD_USER_WORKSPACE_SETTINGS:
    //   return {
    //     ...state,
    //     [action.payload.key]: [
    //       ...state[action.payload.key],
    //       action.payload.data,
    //     ],
    //   };

    case Type.ADD_USER_WORKSPACE_SETTINGS:
      const updatedArray = [...state[action.payload.key], action.payload.data];

      console.log("Updated Array:", updatedArray); // Log here

      return {
        ...state,
        [action.payload.key]: updatedArray,
      };

    case Type.UPDATE_USER_WORKSPACE_SETTINGS:
      return {
        ...state,
        [action.payload.key]: state[action.payload.key].map((item) =>
          item?._id === action.payload.data?._id
            ? { ...item, ...action.payload.data }
            : item
        ),
      };

    //     case Type.UPDATE_USER_WORKSPACE_SETTINGS:
    // console.log(action.payload.data, "action.payload.data");

    // const updatedArray = state[action.payload.key].map((item) =>
    //   item?._id === action.payload.data?._id
    //     ? { ...item, ...action.payload.data }
    //     : item
    // );

    // console.log(updatedArray, "Updated Array after UPDATE_USER_WORKSPACE_SETTINGS");

    // return {
    //   ...state,
    //   [action.payload.key]: updatedArray,
    // };

    case Type.REMOVE_FROM_USER_WORKSPACE_SETTINGS:
      console.log(
        state[action.payload.key]?.filter(
          (item) => (item?._id || item?.id) !== action.payload.id
        ),
        action.payload.id,
        "action.payload.idaction.payload.id"
      );
      return {
        ...state,
        [action.payload.key]: state[action.payload.key]?.filter(
          (item) => (item?._id || item?.id) !== action.payload.id
        ),
      };

    default:
      return state;
  }
};

export default UserWorkspaceSettingsReducer;
